<!-- 情绪胶囊 -->
<template>
  <div>
    <van-nav-bar
      title="情绪胶囊"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="capsule">
      <div class="ctx1">
        <div class="ctx-title">
          <div class="title-ctx">紧握身边的一个物品</div>
          <div class="title-fk"></div>
        </div>
        <div class="ctx1-content1">
          看看现在身边有什么可以握在手里的物品 用力握住它，体验攥紧它的感受
          试着把注意力带到你的手指上，体验皮肤和这个物品的接触
          感受一下它的温度、形状、大小、质感......
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">很生气、很愤怒的时候</div>
            <div class="ctx-top">焦虑、紧张的时候</div>
            <div class="ctx-top">害怕、担忧的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            将注意力带到感官，觉察到身体的感受可以让把我们暂时从强烈的情绪中带离，让我们的感官和思绪都暂时回到当下，使我们能更好地应对当下的情况(Williams
            & Penman, 2016; Forsyth & Eifert, 2016)。
          </div>
        </div>
      </div>

      <div class="ctx2 ctx1">
        <div class="ctx-title">
          <div class="title-ctx">盒式呼吸</div>
          <div class="title-fk"></div>
        </div>
        <div class="ctx2-content">
          <div>深深地吸气4秒钟，屏住呼吸4秒钟</div>
          <div>张开嘴，用4秒钟，彻底地将气均匀呼出</div>
          <div>屏住呼吸4秒钟； 再均匀地吸气4秒</div>

          <div>重复这个过程(如下图)</div>
        </div>
        <div class="ctx2-huxi">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/huxi.png"
            alt=""
          />
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">需要“缓一缓”的时候</div>
            <div class="ctx-top">很慌乱，需要冷静一下的时候</div>
            <div class="ctx-top">心情烦躁，思绪杂乱的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            呼吸的调整可以帮助我们找回对当下和身体的“控制感”，减少失控的感受，能够让我们平静下来，找回冷静、条理清晰的感觉(Catherine,
            2010; Joyce, Shand, Bryant, Lal, & Harvey, 2018; Vago & Silbersweig,
            2012)。
          </div>
        </div>
      </div>

      <div class="ctx1 ctx2">
        <div class="ctx-title">
          <div class="title-ctx">用力推墙</div>
          <div class="title-fk"></div>
        </div>
        <div class="ctx1-content1">
          用双手用力地推墙壁，就像把身体被阻碍或压抑的部分被“推”了出去。
        </div>
        <div class="ctx2-tuiqiang">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/fuqiang.png"
            alt=""
          />
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">很愤怒，很生气的时候</div>
            <div class="ctx-top">感到痛苦、难过的时候</div>
            <div class="ctx-top">情绪起伏激烈的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            通过参与身体的感受和活动，具身技术能够减少大脑的过度活动，缓和激烈的情绪冲击，，帮助我们平复情绪(Kolter,
            2014)。
          </div>
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">特别提醒</div>
          <div class="ctx1-content">
            <div class="ctx-top">注意安全，避免受伤</div>
          </div>
        </div>
      </div>

      <div class="ctx1 ctx2">
        <div class="ctx-title">
          <div class="title-ctx">听听大自然的声音</div>
          <div class="title-fk"></div>
        </div>
        <div class="ctx2-content">
          <div>
            有时候大自然的声音具有天然的疗愈力量 给自己5分钟的时间听听它的声音
          </div>
        </div>
        <!-- 音频 -->

        <div class="mp3-box">
          <div class="mp3-header">
            <div class="name">听听大自然的声音</div>
            <img
              class="btn"
              @click="playAudio1()"
              v-if="audioshow1"
              src="https://lesson.iapeap.com/images/EmotionalValue/kaishi.png"
              alt=""
            />
            <img
              class="btn"
              v-if="!audioshow1"
              @click="playAudio1()"
              src="https://lesson.iapeap.com/images/EmotionalValue/zanting.png"
              alt=""
            />
          </div>
          <div class="mp3-content">
            <van-slider :disabled="true" v-model="currentTime1" />
            <div class="mp3-info">
              <div class="currentTime">{{ formatCurrentTime1 }}</div>
              <div class="totalTime">{{ formatTotalTime1 }}</div>
            </div>
          </div>
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">压力很大，感觉很疲劳的时候</div>
            <div class="ctx-top">想要平静，换一换心情的时候</div>
            <div class="ctx-top">有些孤单、低落的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            自然舒缓的声音可以缓解压力带来的紧张和疲惫（Levy,
            2017)，并且它促进我们的内啡肽循环(Mockel et al.,
            1994)，帮助我们调整心情，带来舒适、平静，放松的体验，增加愉悦的感受(Koelsch
            et al., 2009)。
          </div>
        </div>
      </div>

      <div class="ctx1 ctx2">
        <div class="ctx-title">
          <div class="title-ctx">运动一会</div>
          <div class="title-fk"></div>
        </div>
        <div class="ctx2-content">
          <div>
            选择你熟悉的一种运动 比如跑步、散步，或者原地做几个蹲起
            让自己专注在身体肌肉的活动上
          </div>
        </div>

        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">烦躁不安的时候</div>
            <div class="ctx-top">感到挫败、失望的时候</div>
            <div class="ctx-top">心里压抑、难受，想要发泄的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            运动能普遍地让人们有“感觉更好”的效果(Biddle & Ekkekakis,
            2012)，它能减少烦闷、压抑等感受，带给我们愉悦的积极体验(Yeung,
            1996)。
          </div>
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">特别提醒</div>
          <div class="ctx1-content">
            <div class="ctx-top">运动时要适度，注意安全，避免受伤和危险。</div>
          </div>
        </div>
      </div>

      <div class="ctx1 ctx2">
        <div class="ctx-title">
          <div class="title-ctx">蝴蝶拍</div>
          <div class="title-fk"></div>
        </div>
        <div class="ctx1-content1">
          把双手交叉放在胸前 左右交替轻拍肩膀，就像蝴蝶的翅膀
          选择让自己舒适的力度，节奏可以慢一些 轻拍时可以在心里默念，
          “我现在是安全的” “我正被温柔地照顾着”
          在过程中，允许自己的头脑中自然浮现想法，
          感受身体的各种感觉，允许它们自然而然地出现。
          拍打10次左右时，可以停下来做一次深呼吸，
          感受一下更加平静和安全的感受。 可以重复这个过程，多做几次
        </div>
        <div class="ctx2-hudiepai">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/hudiepai.png"
            alt=""
          />
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">痛苦悲伤、难过，的时候</div>
            <div class="ctx-top">失望、受挫的时候</div>
            <div class="ctx-top">自责、委屈的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            蝴蝶拍技术结合轻拍和呼吸等，温和地传递着“我很安全”、“我被照顾着”的感受，它可以给我们带来温暖的关怀和支持，让我们从负面情绪中恢复
          </div>
        </div>
      </div>

      <div class="ctx1 ctx2">
        <div class="ctx-title">
          <div class="title-ctx">身体扫描</div>
          <div class="title-fk"></div>
        </div>

        <div class="ctx2-content">
          <div>
            允许自己有不被打扰的5分钟 跟随音频，觉察和关注身体的感受，休息一下
          </div>
        </div>
        <div class="mp3-box">
          <div class="mp3-header">
            <div class="name">身体扫描</div>
            <img
              class="btn"
              @click="playAudio2()"
              v-if="audioshow2"
              src="https://lesson.iapeap.com/images/EmotionalValue/kaishi.png"
              alt=""
            />
            <img
              class="btn"
              v-if="!audioshow2"
              @click="playAudio2()"
              src="https://lesson.iapeap.com/images/EmotionalValue/zanting.png"
              alt=""
            />
          </div>
          <div class="mp3-content">
            <van-slider :disabled="true" v-model="currentTime2" />
            <div class="mp3-info">
              <div class="currentTime">{{ formatCurrentTime2 }}</div>
              <div class="totalTime">{{ formatTotalTime2 }}</div>
            </div>
          </div>
        </div>
        <div class="ctx1-content2">
          <div class="ctx-blue">适用场景</div>
          <div class="ctx1-content">
            <div class="ctx-top">不安和紧张的时候</div>
            <div class="ctx-top">静不下心来，感觉很烦躁的时候</div>
            <div class="ctx-top">想要放松，休息一下的时候</div>
            <div class="ctx-top">心情不好，想要调整一下的时候</div>
          </div>
        </div>
        <div class="ctx1-content3">
          <div class="ctx-blue">胶囊原理</div>
          <div class="content3">
            身体扫描让我们和当下身体的感受待在一起，能够帮助我们缓解压力(Grossman,
            Niemann, Schmidt, & Walach,
            2004)，减少烦躁、紧张、不安的情绪(Ussher, Cropley, Playle, Mohidin,
            & West,
            2009)，研究结果证明，它对焦虑、压力以及情绪低落的改善都很有帮助(Lengacher
            et al., 2009)。
          </div>
        </div>
      </div>

      <div class="ctx1 ctx2">
        <div class="ctx-title">
          <div class="title-ctx">参考文献</div>
          <div class="title-fk"></div>
        </div>
        <div class="cankaowenxian">
          Biddle, S. J., & Ekkekakis, P. (2005). Physically active lifestyles
          and well-being. The science of well-being, 140, 168. Catherine, S.
          (2010). Focused and fearless: A meditator's guide to states of deep
          joy, calm, and clarity. ReadHowYouWant. com. Forsyth, J. P., & Eifert,
          G. H. (2016). The mindfulness and acceptance workbook for anxiety: A
          guide to breaking free from anxiety, phobias, and worry using
          acceptance and commitment therapy. New Harbinger Publications.
          Grossman, P., Niemann, L., Schmidt, S., & Walach, H. (2004).
          Mindfulness-based stress reduction and health benefits: A
          meta-analysis. Journal of psychosomatic research, 57(1), 35-43. Joyce,
          S., Shand, F., Bryant, R. A., Lal, T. J., & Harvey, S. B. (2018).
          Mindfulness-based resilience training in the workplace: Pilot study of
          the internet-based Resilience@ Work (RAW) mindfulness program. Journal
          of medical Internet research, 20(9), e10326. Kotler, S. (2014). The
          rise of superman: Decoding the science of ultimate human performance.
          Houghton Mifflin Harcourt. Koelsch, S. (2009). A neuroscientific
          perspective on music therapy. Annals of the New York Academy of
          Sciences, 1169(1), 374-384. Levy, Jillian (2017). Music therapy:
          Benefits and uses for anxiety, depression and more. Retrieved from
          https://draxe.com/music-therapy-benefits Luber, M. (Ed.).
          (2013). Implementing EMDR early mental health interventions for
          man-made and natural disasters: Models, scripted protocols and summary
          sheets. Springer Publishing Company. Lengacher, C. A.,
          Johnson‐Mallard, V., Post‐White, J., Moscoso, M. S., Jacobsen, P. B.,
          Klein, T. W., ... & Kip, K. E. (2009). Randomized controlled trial of
          mindfulness‐based stress reduction (MBSR) for survivors of breast
          cancer. Psycho‐Oncology: Journal of the Psychological, Social and
          Behavioral Dimensions of Cancer, 18(12), 1261-1272. Möckel, M.,
          Röcker, L., Störk, T., Vollert, J., Danne, O., Eichstädt, H., ... &
          Hochrein, H. (1994). Immediate physiological responses of healthy
          volunteers to different types of music: cardiovascular, hormonal and
          mental changes. European journal of applied physiology and
          occupational physiology, 68, 451-459. Ussher, M., Cropley, M., Playle,
          S., Mohidin, R., & West, R. (2009). Effect of isometric exercise and
          body scanning on cigarette cravings and withdrawal
          symptoms. Addiction, 104(7), 1251-1257. Vago, D. R., & Silbersweig, D.
          A. (2012). Self-awareness, self-regulation, and self-transcendence
          (S-ART): a framework for understanding the neurobiological mechanisms
          of mindfulness. Frontiers in human neuroscience, 6, 296. Williams, M.,
          & Penman, D. (2011). Mindfulness: A practical guide to finding peace
          in a frantic world. Hachette UK. Yeung, R. R. (1996). The acute
          effects of exercise on mood state. Journal of psychosomatic
          research, 40(2), 123-141.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  memberLog,
} from "../../api/login";
export default {
  data() {
    return {
      audio1:
        "https://lesson.iapeap.com/EmotionalVal/Media/qingxujiaonang_zirandeshengyin.mp3",
      audioshow1: true,
      currentTime1: 0,
      totalTime1: 0,
      audio2:
        "https://lesson.iapeap.com/EmotionalVal/Media/qingxujiaonang_shentisaomiao.MP3",
      audioshow2: true,
      currentTime2: 0,
      totalTime2: 0,
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  computed: {
    formatCurrentTime1() {
      return this.formatTime(this.currentTime1);
    },
    formatTotalTime1() {
      return this.formatTime(this.totalTime1);
    },
    formatCurrentTime2() {
      return this.formatTime(this.currentTime2);
    },
    formatTotalTime2() {
      return this.formatTime(this.totalTime2);
    },
  },
  methods: {
    goBack() {
      this.audio1.pause();
      this.audioshow1 = false;
      this.audio2.pause();
      this.audioshow2 = false;
      this.$router.go(-1);
    },
    playAudio1() {
      if (this.audio1) {
        if (this.audio1.paused) {
          this.audio1.play();
          this.audioshow1 = false;
        } else {
          this.audio1.pause();
          this.audioshow1 = true;
        }
      }
    },
    playAudio2() {
      if (this.audio2) {
        if (this.audio2.paused) {
          this.audio2.play();
          this.audioshow2 = false;
        } else {
          this.audio2.pause();
          this.audioshow2 = true;
        }
      }
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    updateProgressBar1() {
      if (this.audio1) {
        this.currentTime1 = this.audio1.currentTime;
        this.totalTime1 = this.audio1.duration;
      }
    },
    updateProgressBar2() {
      if (this.audio2) {
        this.currentTime2 = this.audio2.currentTime;
        this.totalTime2 = this.audio2.duration;
      }
    },
    initAudio1() {
      this.audio1 = new Audio(
        "https://lesson.iapeap.com/EmotionalVal/Media/qingxujiaonang_zirandeshengyin.mp3"
      );
      this.audio1.addEventListener("timeupdate", this.updateProgressBar1);
      this.audio1.addEventListener("ended", () => {
        this.audioshow1 = true;
        this.currentTime1 = 0;
      });
    },
    initAudio2() {
      this.audio2 = new Audio(
        "https://lesson.iapeap.com/EmotionalVal/Media/qingxujiaonang_shentisaomiao.MP3"
      );
      this.audio2.addEventListener("timeupdate", this.updateProgressBar2);
      this.audio2.addEventListener("ended", () => {
        this.audioshow2 = true;
        this.currentTime2 = 0;
      });
    },
  },
  destroyed() {
    this.audio1.pause();
    this.audioshow1 = false;
    this.audio2.pause();
    this.audioshow2 = false;
  },
  created() {
    this.initAudio1();
    this.initAudio2();
    // Toast.loading({
    //   duration: 0, // 持续展示 toast
    //   message: "正在加载音频",
    //   forbidClick: true,
    // });
    // setTimeout(() => {
    //   Toast.clear();
    // }, 2000);
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    if (this.audio1) {
      this.audio1.removeEventListener("timeupdate", this.updateProgressBar1);
      this.audio1.pause();
      this.audio1 = null;
    }
    if (this.audio2) {
      this.audio2.removeEventListener("timeupdate", this.updateProgressBar2);
      this.audio2.pause();
      this.audio2 = null;
    }



    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "情绪胶囊",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.van-slider__button {
  width: 5px;
  height: 5px;
  background-color: rgb(101, 160, 233);
  border-radius: 50%;
  box-shadow: 0 0.01rem 0.02rem rgba(0, 0, 0, 0.5);
}
.mp3-box {
  height: 85px;
  background-color: rgb(238, 247, 254);
  border: solid 1px #629ee9;
  border-radius: 15px;
  padding: 10px 20px;
  box-sizing: border-box;
  .mp3-header {
    display: flex;
    justify-content: space-between;
  }
  .mp3-content {
    margin-top: 10px;
  }
  .btn {
    height: 25px;
    width: 25px;
  }
  .mp3-info {
    color: #629ee9;
    font-size: 13px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .name {
    color: #629ee9;
  }
}
.navbar {
  width: 100%;
  background-color: #ededed;
  z-index: 999;
  .van-nav-bar__arrow {
    color: #464646;
  }
}
.capsule {
  // position: absolute;
  z-index: 0;
  padding: 20px;
  box-sizing: border-box;

  .ctx-blue {
    width: 92px;
    height: 27px;
    background: #629ee9;
    border-radius: 0px 0px 8px 0px;
    text-align: center;
    font-size: 13px;
    line-height: 27px;
    color: #fff;
    border-radius: 8px 0 8px 0;
  }
  .ctx1-content {
    padding: 20px;
    .ctx-top {
      height: 18px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  .ctx-title {
    .title-ctx {
      z-index: 999;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 998;
      transform: skew(-25deg);
    }
  }

  .ctx1 {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    .ctx1-content1 {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
      text-indent: 20px;
    }
    .ctx1-content2 {
      background-color: #f6f9fd;
      margin-top: 10px;
    }
    .ctx1-content3 {
      // width: 296px;
      background: #f6fbfd;
      border-radius: 8px;
      margin-top: 20px;
      .content3 {
        font-size: 13px;
        font-weight: 300;
        color: #000025;
        line-height: 18px;
        text-indent: 20px;
        margin-top: 10px;
        padding: 0 20px;
      }
    }
  }

  .ctx2 {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    .ctx2-content {
      height: 72px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
    }
    .ctx2-huxi {
      text-align: center;
      img {
        width: 209px;
        height: 188px;
      }
    }
    .ctx2-hudiepai {
      margin-top: 10px;
      text-align: center;
      margin-bottom: 10px;
      img {
        width: 106px;
        height: 185px;
      }
    }
    .ctx2-tuiqiang {
      margin-top: 10px;
      text-align: center;
      margin-bottom: 10px;

      img {
        width: 152px;
        height: 185px;
      }
    }
  }
  .cankaowenxian {
    font-size: 10px;
    font-weight: 100;
    color: #7b7b85;
    line-height: 14px;
    margin-top: 10px;
  }
}
</style>
